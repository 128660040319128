<template>
    <!-- 摄影师详情路由 -->
    <div class="lensman-detail-box">
        <member-detail-banner></member-detail-banner>
        <member-detail-info
            service-router="lensmanService">
        </member-detail-info>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    components: {
        memberDetailBanner: () => import("@/components/memberDetailBanner"),
        memberDetailInfo: () => import("@/components/memberDetailInfo"),
    },
    methods: {
        ...mapMutations([
            "setPersonalData",
            'setMemberServiceList'
        ]),
        ...mapActions([
            "getLensmanDetail",
            'getMemberServiceList'
        ]),
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getLensmanDetail(to.query.id);
            vm.getMemberServiceList(to.query.id);
        });
    },
    beforeRouteLeave (to, from, next) {
        this.setPersonalData({});
        this.setMemberServiceList({});
        next();
    },
}
</script>